import React from 'react'
import classnames from 'classnames';
import { Link } from 'gatsby'

import PreviewCompatibleImage from '../PreviewCompatibleImage';
import styles from './styles.module.scss';

const ChildrenContainer = ({ href, children }) => {
  if (href) return <Link to={href} className={styles.link}>{children}</Link>
  return children;
}

export const Card = ({ children, background, href, className }) => (
  <div className={classnames(styles.card, className)}>
    {background && (
      <PreviewCompatibleImage
       imageInfo={background} />
    )}
    <div
      className={styles.content}
    >
      <ChildrenContainer href={href}>
        {children}
      </ChildrenContainer>
    </div>
  </div>
)

export default Card;
