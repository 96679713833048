import React from 'react'
import classnames from 'classnames';

import styles from './styles.module.scss';

export const Row = ({ children, className }) => (
  <div className={classnames(styles.row, className)}>
    <section className={styles.row__content}>
      {children}
    </section>
  </div>
)

export default Row
