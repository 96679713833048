import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';

import Home from '../views/Home';

export const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout navbar={false}>
      <Home {...frontmatter} />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        main {
          picture {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 75) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          label
          link
          content
        }
        blog {
          title
          content
          picture {
            childImageSharp {
              fluid(maxHeight: 600, quality: 75) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        shop {
          title
          content
          wines {
            image {
              childImageSharp {
                fluid(maxHeight: 600, quality: 75) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            name
            link
          }
        }
        pages {
          title
          content
          pages {
            title
            image {
              childImageSharp {
                fluid(maxHeight: 600, quality: 75) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            link
          }
        }
      }
    }
  }
`;
