import React from 'react'

import Card from '../../../components/Card';
import PreviewCompatibleImage from '../../../components/PreviewCompatibleImage';
import styles from './styles.module.scss';

export const Wines = ({ wines }) => (
  <>
    {wines.map(({ name, image, link }) => (
      <Card key={name} className={styles.wine}>
        <PreviewCompatibleImage imageInfo={{ image }} className={styles.wine__image} />
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.wine__link}
        >
          <h3>{name}</h3>
        </a>
      </Card>
    ))}
  </>
)

export default Wines;
