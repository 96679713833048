import React from 'react';
import { Link } from 'gatsby';
import classnames from 'classnames';

import Row from '../../components/Row';
import Card from '../../components/Card';
import Wines from './Wines';
import PreviewCompatibleImage from '../../components/PreviewCompatibleImage';
import { HTMLContent } from '../../components/Content';
import styles from './styles.module.scss';

import '../ui.css';

export const Home = ({
  title,
  main: {
    picture: mainPicture,
    label: mainLabel,
    link: mainLink,
    content: mainContent,
  },
  blog: { title: blogTitle, content: blogContent, picture: blogImage },
  shop: { title: shopTitle, content: shopContent, wines },
  pages: { title: pagesTitle, content: pagesContent, pages },
}) => (
  <div className={styles.home}>
    <h1 className={styles.home__title}>
      <a href="/" className={styles.link}>
        {title}
      </a>
    </h1>

    <HTMLContent content={mainContent} />

    <Row className={classnames(styles.home__sectionMain, styles.sectionMain)}>
      <Card>
        <PreviewCompatibleImage
          imageInfo={{
            image: mainPicture,
          }}
        />
        <div className={styles.sectionMain__content}>
          <Link
            to={mainLink}
            className={classnames(styles.sectionMain__link, 'button')}
          >
            {mainLabel}
          </Link>
        </div>
      </Card>
    </Row>

    <h2>
      <a href="/actu" className={styles.link}>
        {blogTitle}
      </a>
    </h2>
    <HTMLContent content={blogContent} />
    <Row>
      <Card>
        <a href="/actu" className={styles.link}>
          <PreviewCompatibleImage imageInfo={{ image: blogImage }} />
        </a>
      </Card>
    </Row>

    <h2>
      <a href="https://domaine-gayrard.plugwine.com/" className={styles.link}>
        {shopTitle}
      </a>
    </h2>
    <HTMLContent content={shopContent} />
    <Row>
      <Wines wines={wines} />
    </Row>

    <h2>
      <a href="/tourisme/" className={styles.link}>
        {pagesTitle}
      </a>
    </h2>
    <HTMLContent content={pagesContent} />
    <Row className={styles.sectionPages}>
      {pages.map(({ title, image, link }) => (
        <Card key={`${title}${link}`} className={styles.sectionPages__page}>
          <Link to={link} className={classnames(styles.sectionPages__link)}>
            <div className={styles.sectionPages__image}>
              <PreviewCompatibleImage imageInfo={{ image }} />
            </div>
            {title}
          </Link>
        </Card>
      ))}
    </Row>
  </div>
);

export default Home;
